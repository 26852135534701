























































































import {
  computed, defineComponent,
  ref, useRoute,
} from '@nuxtjs/composition-api';
import { SfButton, SfCallToAction, SfHeading } from '@storefront-ui/vue';

import { addBasePath } from '~/helpers/addBasePath';

export default defineComponent({
  components: {
    SfHeading,
    SfButton,
    SfCallToAction,
  },
  setup(props, context) {
    context.emit('changeStep', 4);
    const route = useRoute();

    const companyDetails = ref({
      name: 'Vue Storefront Headquarter',
      street: 'St. Dmowskiego 17, 53-534',
      city: 'Wroclaw, Poland',
      email: 'demo@vuestorefront.io',
    });
    const orderNumber = computed(() => route.value.query.order ?? '');

    return {
      addBasePath,
      companyDetails,
      orderNumber,
    };
  },
});
